import debounce from 'lodash/debounce';
import { useState, DependencyList, useEffect } from 'react';

export interface IUseDebounceMemoProps<T> {
  factory: () => T;
  wait?: number;
}

export default function useDebouncedMemo<T>({ factory, wait }: IUseDebounceMemoProps<T>, deps: DependencyList): T {
  const [value, setValue] = useState<T>(factory);

  useEffect(() => {
    const handleDebouncedChange = debounce(() => {
      console.log('Debounced value: ', factory());
      setValue(factory);
    }, wait);

    handleDebouncedChange();

    return () => {
      handleDebouncedChange.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wait, ...deps]);

  return value;
}
