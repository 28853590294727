import classNames from 'classnames';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  useTransition,
} from 'react';
import styles from './RideBooking.module.scss';
import AppStepper from '../../components/AppStepper/AppStepper';
import { useAppStepper } from '../../components/AppStepper/useAppStepper';
import Button from '../../components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationDialog } from '../../components/ConfirmationDialog/useConfirmationDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../images/cancel-current-color.svg?tsx';
import {
  fetchSummary,
  getAvailableTimes,
  getTimeSlots,
  getAvailableDates,
  getFirstAvailableDate,
  createReservation,
  getDatesDiscounts,
} from '../../store/booking/booking.actions';
import {
  SummaryType,
  EAvailableDateStatus,
  TimeSlot,
} from '../../store/booking/booking.types';
import { toastUtil } from '../../utils/toast.utils';
import { Lounge } from '../../store/lounge/lounge.types';
import useWithSelection from '../../hooks/useWithSelection';
import { loungeSelector } from '../../store/lounge/lounge.selectors';
import RideSummary from './components/steps/RideSummary/RideSummary';
import BookingSummary from '../../components/BookingSummary';
import { RIDE_BOOKING_STEPS } from './constants/steps';
import LocationStep from './components/steps/LocationStep/LocationStep';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getLounge } from '../../store/lounge/lounge.actions';
import ExperienceStep from './components/steps/ExperienceStep/ExperienceStep';
import BookingDetailsList, {
  BookingDetail,
} from '../../components/BookingDetailsList/BookingDetailsList';
import { getLoungeAddress } from '../../utils/get-lounge-address.utils';
import BookingPriceDetails, {
  BookingPriceDetail,
} from '../../components/BookingPriceDetails/BookingPriceDetails';
import { round } from '../../utils/round.utils';
import ThankYou from './components/steps/ThankYou/ThankYou';
import LoginDialog from '../../components/LoginDialog/LoginDialog';
import { ERideBookingStep } from './enums/steps';
import { customerSelector } from '../../store/customer/customer.selectors';
import { Customer } from '../../store/customer/customer.types';
import { RIDE_BESTSELLER_DURATION } from './constants/ride-bestseller-duration';
import { timeFromIsoDate } from '../../utils/time-from-iso-date.utils';
import { ICalendarDayProps } from '../../components/Calendar/CalendarDay';
import { ILinerCalendarOnGenerateChunkProps } from '../../components/Calendar/LinearCalendar';
import { formatISO, parseISO, format } from 'date-fns';
import { EnhancedDate } from '../../utils/enhanced-date.utils';
import { signUp } from '../../store/customer/customer.actions';
import camelCase from 'lodash/camelCase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisableableEffect } from '../../hooks/useDisableableEffect';
import scrollToError from '@utils/scroll-to-error.utils';
import useDebouncedMemo from '../../hooks/useDebouncedMemo';

export enum EBookingPayment {
  lounge,
  card,
}

export interface IRideBookingForm {
  participantsAmount: number;
  loungeId: string;
  timeSlotId: string;
  duration: number;
  date: Date | null;
  time: string;
  coupon: string;
  firstName: string;
  lastName: string;
  email: string;
  payment: EBookingPayment;
  createUser: boolean;
}

const RideBooking: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const [, startTransition] = useTransition();
  const loungeDispatch = useWithDispatch(getLounge);
  const { openDialog, dialog } = useConfirmationDialog();
  const { currentStep, latestVisitedStep, nextStep, prevStep, setCurrentStep }
    = useAppStepper(RIDE_BOOKING_STEPS, 0);
  const form = useForm<IRideBookingForm>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      participantsAmount: 1,
      loungeId: '',
      timeSlotId: '',
      duration: 0,
      date: null,
      time: '',
      firstName: '',
      lastName: '',
      email: '',
      payment: EBookingPayment.lounge,
      coupon: '',
      createUser: false,
    },
  });
  const lounges: Lounge[] = useWithSelection(loungeSelector);
  const customer: Customer = useWithSelection(customerSelector);
  const prevCustomerRef = useRef<Customer>(customer);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [isFirstAvailableDateLoading, setIsFirstAvailableDateLoading]
    = useState<boolean>(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(true);
  const [isTimesLoading, setIsTimesLoading] = useState<boolean>(true);
  const [isDatesLoading, setIsDatesLoading] = useState<boolean>(true);
  const [isTimeSlotsLoading, setIsTimeSlotsLoading] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [priceSummary, setPriceSummary] = useState<SummaryType | null>(null);

  const {
    email,
    firstName,
    lastName,
    loungeId,
    duration,
    timeSlotId,
    date,
    time,
    participantsAmount: currentParticipantsAmount,
    coupon,
    createUser,
  } = form.watch([
    'email',
    'firstName',
    'lastName',
    'loungeId',
    'duration',
    'timeSlotId',
    'participantsAmount',
    'date',
    'time',
    'coupon',
    'createUser',
  ]);

  const participantsAmount = useDebouncedMemo({
    factory: () => currentParticipantsAmount,
    wait: 500,
  }, [currentParticipantsAmount]);

  const { setValue: setFormValue, setError: setFormError } = form;

  const isGroupRide = useMemo(
    () => participantsAmount > 1,
    [participantsAmount],
  );

  const [closestAvailableDate, setClosestAvailableDate] = useState<
    Date | undefined
  >();

  useEffect(() => {
    if (!participantsAmount || !loungeId || !timeSlotId) return;
    setTimeout(() => setIsFirstAvailableDateLoading(true), 0);

    const abortController = new AbortController();

    getFirstAvailableDate(
      +participantsAmount,
      timeSlotId,
      loungeId,
      abortController,
    )
      .then((isoDate) => {
        const parsedDate = isoDate ? parseISO(isoDate) : undefined;
        setClosestAvailableDate(parsedDate);
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsFirstAvailableDateLoading(false));

    return () => {
      abortController.abort();
    };
  }, [participantsAmount, timeSlotId, loungeId, customer]);

  const availableDatesFetchRequestControllerRef
    = useRef<AbortController | null>(null);

  useEffect(() => {
    setClosestAvailableDate(undefined);
    return () => {
      if (!availableDatesFetchRequestControllerRef.current) return;
      availableDatesFetchRequestControllerRef.current.abort();
    };
  }, [timeSlotId]);

  const [datesData, setDatesData] = useState<
    Record<string, Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>>
  >({});

  const fetchDatesRangeData = useCallback(
    ({ from, to }: ILinerCalendarOnGenerateChunkProps) => {
      if (!timeSlotId) return;

      startTransition(() => {
        (async () => {
          try {
            if (!closestAvailableDate) setIsDatesLoading(true);

            const abortController = new AbortController();
            availableDatesFetchRequestControllerRef.current = abortController;

            const [isoFromDate] = formatISO(from).split('T');
            const [isoToDate] = formatISO(to).split('T');

            const [newlyLoadedBookingDates, datesDiscounts] = await Promise.all([
              await getAvailableDates(
                +participantsAmount,
                timeSlotId,
                loungeId,
                isoFromDate,
                isoToDate,
                '',
                abortController,
              ),
              await getDatesDiscounts({
                loungeId,
                timeSlotId,
                fromDate: new EnhancedDate(from).isoDate,
                toDate: new EnhancedDate(to).isoDate,
                isBooking: true,
                abortController,
              }),
            ]);

            const newDatesData = newlyLoadedBookingDates.reduce((data, date) => {
              const enhancedDate = new EnhancedDate(parseISO(date.date));

              return {
                ...data,
                [enhancedDate.isoDate]: {
                  barColor: date.usage_color,
                  discount:
                    datesDiscounts && enhancedDate.isoDate in datesDiscounts
                      ? datesDiscounts[enhancedDate.isoDate].tag
                      : undefined,
                  disabled:
                    date.status !== (EAvailableDateStatus.Bookable as string),
                },
              };
            }, {} as Record<string, Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>>);

            setDatesData((prevDatesData) => ({
              ...prevDatesData,
              ...newDatesData,
            }));
          }
          catch (err) {
            const error = err as TRumpApiRequestError;
            if ('message' in error && error.message === 'canceled') return;

            const msg = 'meta' in error ? error.meta.message : error.message;
            if (typeof msg === 'string') toastUtil('error', msg);
          }
          finally {
            setIsDatesLoading(false);
          }
        })();
      });
    },
    [participantsAmount, loungeId, timeSlotId, closestAvailableDate],
  );

  const previousTimesSlotIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (
      (timeSlotId === previousTimesSlotIdRef.current && customer === prevCustomerRef.current)
      || !participantsAmount
      || !loungeId
      || !timeSlotId
    )
      return;

    setDatesData({});
    previousTimesSlotIdRef.current = timeSlotId;
  }, [
    closestAvailableDate,
    time,
    timeSlotId,
    participantsAmount,
    loungeId,
    customer,
  ]);

  const handleCalendarChunkGeneration = useCallback(
    ({ from, to }: ILinerCalendarOnGenerateChunkProps) => {
      fetchDatesRangeData({ from, to });
    },
    [fetchDatesRangeData],
  );

  useEffect(() => {
    loungeDispatch();
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [currentStep, isThankYou]);

  useEffect(() => {
    setTimeout(() => setIsTimeSlotsLoading(true), 0);

    const controller = new AbortController();

    getTimeSlots(isGroupRide, loungeId, undefined, controller)
      .then((timeSlots) => {
        setTimeSlots(
          timeSlots.sort(
            (a, b) => a.duration_in_minutes - b.duration_in_minutes,
          ),
        );
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsTimeSlotsLoading(false));

    return () => controller.abort();
  }, [isGroupRide, loungeId, customer, setFormValue]);

  const isoDateTime = useMemo(() => (date ? formatISO(date) : ''), [date]);

  useEffect(() => {
    if (
      !loungeId
      || !isoDateTime
      || !timeSlotId
      || !duration
      || !participantsAmount
    )
      return;

    setTimeout(() => setIsTimesLoading(true), 0);

    const controller = new AbortController();

    const [isoDate] = isoDateTime.split('T');

    getAvailableTimes(
      loungeId,
      timeSlotId,
      isoDate,
      duration / 60,
      participantsAmount,
      controller,
    )
      .then((times) => {
        setAvailableTimes(times);
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        setPriceSummary(null);
        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsTimesLoading(false));

    return () => controller.abort();
  }, [
    loungeId,
    timeSlotId,
    isoDateTime,
    duration,
    participantsAmount,
    customer,
  ]);

  useEffect(() => {
    if (!loungeId || !timeSlotId || !time || !participantsAmount) return;

    setTimeout(() => setIsSummaryLoading(true));

    const controller = new AbortController();

    fetchSummary(
      {
        start_time: time,
        time_slot_id: timeSlotId,
        simulators: participantsAmount,
        lounge_id: loungeId,
        rides_sharing: false,
        coupon_code: coupon,
      },
      controller,
    )
      .then(({ data }) => setPriceSummary(data ?? null))
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        setPriceSummary(null);
        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsSummaryLoading(false));

    return () => controller.abort();
  }, [loungeId, timeSlotId, time, participantsAmount, coupon, customer]);

  const previousStepRef = useRef(currentStep);

  const searchParams = useMemo(
    () =>
      Array.from(new URLSearchParams(location.search).entries()).reduce(
        (params, [key, value]) => ({ ...params, [key]: value }),
        {} as { loungeId?: string; duration?: string; isGroupRide?: string },
      ),
    [location],
  );

  useDisableableEffect(
    (disableEffect) => {
      disableEffect();
      if (!searchParams.loungeId) return;
      setFormValue('loungeId', searchParams.loungeId);
      setCurrentStep(1);
    },
    [searchParams, setFormValue],
  );

  const isParticipantsAmountFromSearchParamsAppliedRef = useRef(false);

  useDisableableEffect(
    (disableEffect) => {
      disableEffect();
      if (!searchParams.isGroupRide) return;
      setFormValue('participantsAmount', 2);
      setTimeout(() => {
        isParticipantsAmountFromSearchParamsAppliedRef.current = true;
      }, 0);
    },
    [searchParams, setFormValue],
  );

  const isDurationFromSearchParamsAppliedRef = useRef(!searchParams.duration);

  useDisableableEffect(
    (disableEffect) => {
      if (
        currentStep !== 1
        || !searchParams.duration
        || !timeSlots
        || !timeSlots.length
      )
        return;
      disableEffect();
      const parsedDuration = parseInt(searchParams.duration);
      const timeSlot
        = timeSlots.find(
          ({ duration_in_minutes }) => duration_in_minutes === parsedDuration,
        ) || timeSlots[0];
      setFormValue('timeSlotId', timeSlot.id);
      setFormValue('duration', timeSlot.duration_in_minutes);
      setTimeout(() => {
        isDurationFromSearchParamsAppliedRef.current = true;
      }, 0);
    },
    [searchParams, timeSlots, setFormValue],
  );

  const resetToBestsellerTimeSlot = () => {
    const bestSellerOrFirstAvailableTimeSlot
      = timeSlots.find(
        ({ duration_in_minutes }) =>
          duration_in_minutes === RIDE_BESTSELLER_DURATION,
      ) || timeSlots[0];
    if (!bestSellerOrFirstAvailableTimeSlot) return;
    setFormValue('timeSlotId', bestSellerOrFirstAvailableTimeSlot.id);
    setFormValue('duration', RIDE_BESTSELLER_DURATION);
  };
  const resetToBestsellerTimeSlotRef = useRef(resetToBestsellerTimeSlot);
  resetToBestsellerTimeSlotRef.current = resetToBestsellerTimeSlot;

  useEffect(() => {
    if (
      !isParticipantsAmountFromSearchParamsAppliedRef.current
      || previousStepRef.current !== 0
      || currentStep !== 1
    )
      return;
    setFormValue('participantsAmount', 1);
    resetToBestsellerTimeSlotRef.current();
  }, [currentStep, setFormValue]);

  useEffect(() => {
    if (
      !isDurationFromSearchParamsAppliedRef.current
      || !timeSlots
      || !timeSlots.length
      || previousStepRef.current !== 0
      || currentStep !== 1
    )
      return;

    if (customer === prevCustomerRef.current) {
      resetToBestsellerTimeSlotRef.current();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timeSlots]);

  useEffect(() => {
    if (
      !isDurationFromSearchParamsAppliedRef.current
      || !closestAvailableDate
      || previousStepRef.current !== 0
      || currentStep !== 1
    )
      return;

    if (customer === prevCustomerRef.current) {
      setFormValue('date', closestAvailableDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, setFormValue, closestAvailableDate]);

  useEffect(() => {
    if (!availableTimes || previousStepRef.current !== 0 || currentStep !== 1)
      return;

    if (customer === prevCustomerRef.current) {
      setFormValue('time', availableTimes[0]);
    }
    else {
      prevCustomerRef.current = customer;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, availableTimes, setFormValue]);

  useEffect(() => {
    const previousStep = currentStep;
    return () => {
      previousStepRef.current = previousStep;
    };
  }, [currentStep]);

  const onExit = () =>
    openDialog({
      okText: 'booking.exit',
      okIcon: <CloseIcon width={16} />,
    }).then(
      (isConfirmed) =>
        isConfirmed
        && window.open(import.meta.env.REACT_APP_WEBSITE_URL, '_self'),
    );

  const openLogin = () => setLoginOpen(true);

  const selectedLounge = useMemo(
    () => (loungeId && lounges.find(({ id }) => id === loungeId)) || null,
    [loungeId, lounges],
  );

  const summaryDetails = useMemo(() => {
    const loungeAddress = selectedLounge
      ? getLoungeAddress(selectedLounge)
      : '';

    return [
      {
        label: 'booking.steps.summary.where',
        value: selectedLounge?.name,
        caption: loungeAddress,
      },
      {
        label: 'booking.participants',
        value: participantsAmount,
      },
      {
        label: 'booking.steps.summary.when',
        value: time
          ? `${format(new Date(time), 'do MMMM yyyy')},\n${
            timeFromIsoDate(time)?.[0] ?? ''
          }`
          : '',
      },
    ] as BookingDetail[];
  }, [participantsAmount, selectedLounge, time]);

  const priceDetails: BookingPriceDetail[] = useMemo<
    BookingPriceDetail[]
  >(() => {
    if (!priceSummary) return [];

    const discounts = priceSummary
      ? priceSummary.products.flatMap(({ discounts }) => discounts)
      : [];
    const mappedDiscount = discounts.length
      ? discounts.reduce((acc, discount) => {
        const value = +(acc?.[discount.name] || '') + +discount.price_effect;
        acc[discount.name] = value.toString();

        return acc;
      }, {} as Record<string, string>)
      : null;

    const details: BookingPriceDetail[] = [
      ...priceSummary.products.map(({ product_name, base_price }) => {
        return {
          label: `${participantsAmount} x ${product_name}`,
          value: base_price ? round(base_price) : '',
        } as BookingPriceDetail;
      }),
    ];

    if (mappedDiscount) {
      const discountDetails: BookingPriceDetail[] = Object.entries(mappedDiscount).map(([label, value]) => ({
        label,
        value: round(value),
      }));
      details.push(...discountDetails);
    }

    return details;
    // eslint-disable-next-line
  }, [priceSummary, isSummary, t]);

  const [newlyCreatedUserData, setNewlyCreatedUserData] = useState<Customer>();

  const handleBookingSubmit = useCallback(
    async (cardToken: string) => {
      setIsSending(true);

      try {
        let newUser: Customer | undefined = newlyCreatedUserData;

        if (!customer && !newUser) {
          const response = await signUp({
            email,
            first_name: firstName,
            last_name: lastName,
            silent_member: true,
            create_account: createUser,
          });
          newUser = response.data;
          setNewlyCreatedUserData(newUser);
        }

        const user = customer ? customer : newUser;

        await createReservation(
          {
            start_time: time,
            time_slot_id: timeSlotId,
            simulators: participantsAmount,
            lounge_id: loungeId,
            coupon_code: coupon || null,
            rides_sharing: false,
            card_token: cardToken,
            paid_in_cash: !cardToken,
            customer_id: user ? user.id : undefined,
          },
          true,
        );

        setNewlyCreatedUserData(undefined);
        setIsThankYou(true);
      }
      catch (err) {
        const error = err as TRumpApiErrorResponseData;
        if (!error || !error.meta) return;

        if (error?.meta?.errors) {
          error.meta.errors.forEach(
            ({ field, messages }: { field: string; messages: string[] }) => {
              const camelCaseName = camelCase(field);
              setFormError(camelCaseName as keyof IRideBookingForm, {
                type: 'manual',
                message: messages[0],
              });
            },
          );
          toastUtil('error', t('booking.steps.summary.billingInfoError'));
          setTimeout(() => scrollToError());
        }

        if (typeof error?.meta?.message === 'string') {
          toastUtil('error', error.meta.message);
        }
      }

      setIsSending(false);
    },
    [
      setFormError,
      newlyCreatedUserData,
      customer,
      email,
      firstName,
      lastName,
      createUser,
      timeSlotId,
      participantsAmount,
      time,
      coupon,
      loungeId,
      t,
    ],
  );

  const onSelectLounge = useCallback((lounge: Lounge) => {
    if (lounge.id !== loungeId) {
      setFormValue('time', '');
      setAvailableTimes([]);
      setClosestAvailableDate(undefined);
      setDatesData({});
      previousTimesSlotIdRef.current = null;
    }
    setFormValue('loungeId', lounge.id);
    nextStep();
  }, [loungeId, setFormValue, setAvailableTimes, nextStep]);

  const handleParticipantsAmountChange = useCallback(() => {
    setClosestAvailableDate(undefined);
    setFormValue('date', null);
    setFormValue('time', '');
  }, [setFormValue]);

  const isSummaryLoaderActive
    = isSummaryLoading
    || isFirstAvailableDateLoading
    || isDatesLoading
    || isTimeSlotsLoading
    || isTimesLoading;

  const getStepComponent = () => {
    if (isThankYou)
      return (
        <ThankYou
          selectedLounge={selectedLounge}
          summaryDetails={summaryDetails}
        />
      );
    if (isSummary)
      return (
        <RideSummary
          selectedLounge={selectedLounge}
          summaryDetails={summaryDetails}
          priceDetails={priceDetails}
          priceSummary={priceSummary}
          isPriceLoading={isSummaryLoaderActive}
          isSending={isSending}
          toggleSummary={() => {
            setIsSummary(false);
            prevStep();
          }}
          openLogin={openLogin}
          onSubmit={handleBookingSubmit}
        />
      );
    if (currentStep === 0) return <LocationStep onSelectLounge={onSelectLounge} />;
    if (currentStep === 1)
      return (
        <ExperienceStep
          selectedLounge={selectedLounge}
          onForward={() => {
            navigate({ ...location, search: '' }, { replace: true });
            setIsSummary(true);
            nextStep();
          }}
          onBackward={() => {
            navigate({ ...location, search: '' }, { replace: true });
            prevStep();
          }}
          closestAvailableDate={closestAvailableDate}
          datesData={datesData}
          onDatesRangeDataRequest={handleCalendarChunkGeneration}
          onParticipantsAmountChange={handleParticipantsAmountChange}
          priceSummary={priceSummary}
          priceDetails={priceDetails}
          summaryDetails={summaryDetails}
          availableTimes={availableTimes}
          isFirstAvailableDateLoading={isFirstAvailableDateLoading}
          isTimesLoading={isTimesLoading}
          isDatesLoading={isDatesLoading}
          isTimeSlotsLoading={isTimeSlotsLoading}
          isSummaryLoading={isSummaryLoading}
          timeSlots={timeSlots}
          currency={priceSummary ? priceSummary.currency : undefined}
          vat={
            priceSummary && priceSummary.products[0]
              ? priceSummary.products[0].vat
              : undefined
          }
        />
      );
  };

  const isExperienceStep
    = RIDE_BOOKING_STEPS[currentStep].key
    === (ERideBookingStep.experience as string);

  return (
    <FormProvider {...form}>
      <div
        className={classNames(styles.rideBooking, {
          [styles.rideBooking_scrollable]: isSummary,
        })}
      >
        {isSummary || isThankYou
          ? (
              ''
            )
          : (
              <div className={styles.header}>
                <div className={classNames('container', styles.header__content)}>
                  <AppStepper
                    className={styles.header__stepper}
                    steps={RIDE_BOOKING_STEPS}
                    currentStep={currentStep}
                  />
                  <Button
                    appearance="outline"
                    className={styles.header__button}
                    label={t('booking.exit')}
                    icon={<CloseIcon width={16} />}
                    onClick={onExit}
                  />
                </div>
              </div>
            )}
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div
              className={classNames(styles.cardWrapper, {
                [styles.cardWrapper_scrollable]: isExperienceStep && !isSummary,
              })}
              data-step={!isThankYou && RIDE_BOOKING_STEPS[currentStep].key}
            >
              <div className={styles.cardWrapper__card}>
                {getStepComponent()}
              </div>
              {!customer && !isSummary && !isThankYou
                ? (
                    <div className={styles.cardWrapper__caption}>
                      <Trans i18nKey="auth.logIn.caption">
                        <span onClick={() => openLogin()}>Log in</span>
                      </Trans>
                    </div>
                  )
                : (
                    ''
                  )}
            </div>
            {isExperienceStep && !isSummary
              ? (
                  <div className={styles.content__sidebar}>
                    <BookingSummary
                      currency={priceSummary ? priceSummary.currency : 'CHF'}
                      total={
                        priceSummary && priceSummary.products[0]
                          ? round(priceSummary.products[0].final_price)
                          : '-'
                      }
                      vat={
                        priceSummary && priceSummary.products[0]
                          ? priceSummary.products[0].vat
                          : '-'
                      }
                      isLoading={isSummaryLoaderActive}
                    >
                      <h3 className={styles.content__title}>
                        {t('booking.summary')}
                      </h3>
                      {latestVisitedStep > 0 && summaryDetails
                        ? (
                            <BookingDetailsList details={summaryDetails} />
                          )
                        : (
                            ''
                          )}

                      {latestVisitedStep > 0 && summaryDetails
                        ? (
                            <h3 className={classNames(styles.content__title, styles.content__title_mb8)}>
                              {t('booking.price')}
                            </h3>
                          )
                        : (
                            ''
                          )}

                      <BookingPriceDetails
                        details={priceDetails}
                        emptyStateKey="booking.rideSummaryEmptyState"
                      />
                    </BookingSummary>
                  </div>
                )
              : (
                  ''
                )}
          </div>
        </div>

        <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} />
        {dialog}
      </div>
    </FormProvider>
  );
};

export default RideBooking;
