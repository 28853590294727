const booking = {
  pageTitle: 'Reservación',
  pageSubtitle: 'Reserva una sola experiencia o compra una suscripción.',
  pageBtnLabel: 'Reservar ahora',
  loadingPrice: 'Cargando...',
  invalidTime: 'Seleccionar hora',
  title: 'Experiencias individuales',
  groupTitle: 'Reserve un viaje grupal',
  singleRideFormTitle: 'Reserve un viaje individual',
  groupRideFormTitle: 'Reserve un viaje grupal',
  subtitle: 'Configura y reserva tu experiencia.',
  minutes: 'Min',
  simulators: 'Simuladores',
  ridesSharing: 'Compartir Paquetes',
  freeRide: 'Gratis',
  payNow: 'Pagar ahora',
  summary: 'Resumen',
  booking: 'Reservar',
  lounge: 'Lounge',
  duration: 'Duración',
  date: 'Fecha',
  time: 'Hora',
  vatInfo: 'Precios en {{currency}} incl {{vat}}% IVA.',
  excludedVatInfo: 'Precios en {{currency}} excl {{vat}}% IVA.',
  total: 'Total',
  raceDay: 'Día del race',
  currentDay: 'Hoy',
  fullyBooked: 'No disponible',
  nonBookable: 'Cerrado',
  getNow: 'Get Now',
  choseMembershipPackageTitle: 'Comprar suscripción',
  choseMembershipPackageSubtitle:
    'Compra una suscripción y disfruta la última experiencia.',
  package: 'Package',
  description: 'Descripción',
  planUpgradeSuccess: 'Afiliación activa!',
  bookingSuccessTitle: 'Muchas gracias por tu reserva.',
  subscriptionSuccessTitle:
    'Muchas gracias y diviértete con tu nueva suscripción.',
  close: 'Cerrar',
  widgetText: 'Reserve un solo viaje, elija entre nuestras experiencias.',
  widgetTextGroup:
    'Reserve un viaje en grupo, elija entre nuestras experiencias.',
  memberships: 'Membresías',
  price: 'Precio',
  enterCouponCode: 'Código de descuento',
  couponApply: 'Solicitar',
  couponApplied: 'Aplicado!',
  timeSlotError: 'Seleccione un intervalo de tiempo.',
  timeError: 'Seleccione la hora de la reserva.',
  participants: 'Participantes',
  program: 'Programa',
  extra: 'Extra',
  organiser: 'Organizador',
  food: 'Comida',
  summaryAndPayment: 'Resumen y pago',
  priceSummary: 'Resumen de precios',
  saveEventAsDraft: 'Guardar evento como borrador',
  exit: 'Salida',
  createAnAccountInfo:
    'Para guardar un evento como borrador, primero tienes que iniciar sesión o crear una cuenta.',
  savingEventAsADraft: 'Guardar un evento como borrador',
  savingEventAsADraftInfo:
    'Guardará todos los detalles del evento, <1>además de la franja horaria</1>.<br />La franja horaria se bloqueará para usted sólo después de confirmar el evento.',
  deleteDraftEvent: 'Borrar el borrador del evento',
  deleteDraftEventOk: 'Borrar evento borrador',
  deleteDraftEventInfo:
    '¿Estás seguro de que quieres cancelar tu evento?\nEsta acción no se puede deshacer.',
  dateAndTime: 'Fecha y Hora',
  simulatorsAndDuration: 'Simuladores y Duración',
  location: 'Lugar',
  draftEventHasBeenSaved: 'El evento se ha guardado como borrador',
  eventHasBeenCreated: 'Se ha creado el evento',
  noEventDraftFound: 'No se ha encontrado ningún borrador',
  summaryEmptyState: 'Precio no disponible. Configure su evento',
  saveAsDraftDisabledTooltip:
    'Guardar como borrador no está disponible hasta que se hayan completado los pasos de salón, duración y fecha',
  eventBookingTitle: 'Reserva de eventos',
  yourExperience: 'Su experiencia',

  navigationButtons: {
    back: 'Atrás',
    summary: 'Resumen',
  },
  steps: {
    back: 'Volver',
    caption:
      'Si nuestras opciones estándar no se ajustan a tus necesidades, puedes <1>ponerte en contacto</1> con nosotros para organizar un evento a medida.',
    mobileIsNotAllowed:
      'Oops, esta parte aún está en construcción. Si desea utilizarla, active <1>«Modo Escritorio»</1> en su navegador móvil, o utilice <1>su escritorio</1>.',
    participants: {
      title: '¿Cuántos participantes tomarán parte en el evento?',
      subtitle:
        'Esto nos ayudará a calcular la duración de su evento y a sugerirle los salones adecuados.',
      error:
        'El salón seleccionado sólo permite hasta {{participantsCount}} participantes por defecto. Para satisfacer sus necesidades, <1>póngase en contacto con nosotros</1> directamente',
      counterLabel: 'Número de participantes',
      forwardButton: 'Seleccionar sala',
    },
    experience: {
      participants: {
        title: 'Seleccione el número de participantes',
        discountInfo: '¡Hasta un 25% de descuento para viajes en grupo!',
      },
      discountTooltip: {
        title: 'Reserve más simuladores y obtenga descuentos',
      },
      duration: {
        title: 'Seleccione la duración',
        info: 'Puedes seleccionar el formato de la carrera en el Salón',
        cardOptionsInfo: 'Opciones para elegir en el salón',
      },
      minTooltip: 'El número mínimo de participantes es {{value}}.',
      maxTooltip:
        'Para la sala seleccionada, el número máximo de participantes es {{value}}.',
    },
    lounge: {
      title: '¿Dónde le gustaría organizar el acto?',
      forwardButton: 'Seleccione Duración',
    },
    location: {
      title: '¿Dónde le gustaría correr?',
      forwardButton: 'Seleccione la sala y continúe',
      mapButton: 'Mostrar en el mapa',
    },
    duration: {
      title: '¿Cuánto tiempo desea que dure su evento?',
      subtitle:
        'Basándonos en nuestra experiencia, hemos preparado dos versiones para la duración del evento.',
      forwardButton: 'Seleccionar fecha',
      bestseller: 'Nuestro Bestseller',
      counterSuffix: 'horas',
      from: 'de',
      emptyState:
        'Su solicitud necesita un formato especial, <1>póngase en contacto con nuestro equipo.</1>',
    },
    date: {
      title: '¿Cuándo le gustaría celebrar su acto?',
      subtitle:
        'Estas son las fechas posibles para el número de participantes, el lugar y la duración del evento elegidos.',
      forwardButton: 'Seleccione comida y bebida',
      emptySlots:
        'No hay franjas horarias disponibles para este día.\nSi no encuentra una fecha adecuada, póngase en contacto con nosotros:',
      contactUs: 'Contacto Racing Unleashed',
    },
    food: {
      title: '¿Qué te gustaría comer?',
      subtitle:
        'Elija uno de nuestros conjuntos definidos o describa sus deseos especiales.',
      forwardButton: 'Seleccionar Programa de Carrera',
      person: 'persona',
    },
    program: {
      title: '¿Qué estructura de evento prefiere?',
      subtitle:
        'Ofrecemos diferentes tipos de días de carrera. Elija su preferencia o decida in situ.',
      forwardButton: 'Seleccione Extras',
      readMore: 'Más información',
    },
    extra: {
      title: '¿Desea alguna opción adicional?',
      subtitle: 'Elija una o varias opciones adicionales.',
      forwardButton: 'Introducir datos del organizador del evento',
      informationPanel:
        'Asegúrese de que no hay erratas ni errores en el texto.',
      uploadLabel: 'Subir Logotipo',
      viewExamplePhoto: 'Ver foto de ejemplo',
    },
    organiser: {
      title: '¿Quién organiza el acto?',
      subtitle:
        'Díganos con quién debemos ponernos en contacto en relación con este evento.',
      forwardButton: 'Ver resumen',
      organiserDataTitle: 'Organizador del evento',
      billingDataTitle: 'Datos de facturación',
      company: 'Empresa',
      privateCustomer: 'Cliente privado',
    },
    summary: {
      eventSummary: 'Resumen del evento',
      bookingSummary: 'Resumen de la reserva',
      youAreOneStepAway: '¡Está a un paso de arrancar sus motores!',
      where: 'Dónde',
      when: 'Cuándo',
      raceProgram: 'Programa de la carrera',
      editEventDetails: 'Editar detalles del evento',
      editBookingDetails: 'Editar detalles de la reserva',
      yourEventSupervisor: 'Su supervisor de eventos',
      supervisorInfo:
        'Si tiene alguna pregunta o petición, póngase en contacto con el supervisor del evento, {{name}}.',
      confirmButton: 'Confirmar Reserva',
      confirmAndProceedButton: 'Confirmar y Proceder',
      packageSubtitle: 'Decida cómo desea utilizar su paquete.',
      emptyPackage: 'No tienes paquetes compartibles.',
      billingDetails: 'Detalles de facturación',
      payment: 'Pago',
      payInLounge: 'Pagar en la Sala',
      payByCard: 'Tarjeta de crédito',
      specialRequest: 'Solicitud especial',
      specialRequestTooltip:
        'El supervisor de su evento se pondrá en contacto con usted para discutir los detalles.',
      billingTooltip:
        'Si desea utilizar datos diferentes, cierre la sesión y rellene los datos manualmente.',
      billingInfoError: 'Información de facturación incorrecta',
    },
    thankYou: {
      title: '¡Muchas gracias!',
      text: 'En breve recibirá la confirmación por correo electrónico.\nEn caso de cualquier deseo adicional o cambio, ponte en contacto con tu supervisor de eventos.\n¡Nos vemos en la sala!',
      button: 'Racing Unleashed Homepage',
    },
    rideThankYou: {
      title: '¡Gracias, {{name}}!',
      text: 'Le hemos enviado el correo electrónico de confirmación de la reserva.\n¡Esperamos darle la bienvenida a nuestra sala!',
      faqTitle:
        '¿Tengo que llevar algo conmigo? ¿Habrá atención al personal o tendré que averiguarlo todo por mi cuenta?',
      faqText:
        'Visite la sección de preguntas frecuentes de nuestro sitio web para saber más sobre cómo prepararse antes de su visita a la sala VIP.',
      checkFaqButton: 'Consulta las preguntas frecuentes',
      paymentMethod: 'Forma de pago',
    },
  },
};

export default booking;
